<template>
  <div class="content">
    <h1>Labels</h1>

    <div class="flex flex-col gap-8">
      <div
        class="
          flex flex-col md:flex-row gap-2 justify-between
          bg-gray-100 border border-gray-200 rounded-sm px-4 py-2
        "
      >
        <div class="flex flex-col md:flex-row gap-2">
          <button-select
            v-model="label"
            :options="labels"
            size="sm"
          />
          <b-button variant="white" size="sm" @click="createLabel">
            <div class="flex gap-2 items-center">
              <span>New label</span>
              <i class="far fa-plus-square"></i>
            </div>
          </b-button>
        </div>

        <div class="flex gap-2 items-center">
          <router-link to="/label_raw" class="btn btn-sm btn-white">
            Raw
          </router-link>
          <printer-select />
        </div>
      </div>

      <div v-if="label">
        <label-printer
          :id="label"
          @duplicate="onDuplicate"
          @updated="onUpdated"
          @deleted="onDeleted"
        />
      </div>

    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

const ButtonSelect = () => import('@/components/global/ButtonSelect.vue');
const PrinterSelect = () => import('@/components/labels/PrinterSelect.vue');
const LabelPrinter = () => import('@/components/labels/LabelPrinter.vue');

export default {
  name: 'Labels',
  components: {
    ButtonSelect,
    PrinterSelect,
    LabelPrinter,
  },
  data() {
    return {
      loadingCount: 0,
      label: null,
      labels: [],
    };
  },
  methods: {
    selectFirstLabel() {
      this.label = this.labels.length > 0 ? this.labels[0].value : null;
    },
    createLabel() {
      const label = {
        id: uuidv4(),
        label_name: '',
      };
      this.loadingCount++;
      this.$http
        .post(`/labels/${label.id}`)
        .send({ label })
        .then(() => {
          this.label = label.id;
          this.fetchLabels(false);
        })
        .catch((err) => {
          alert(`Failed to create label: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchLabels(resetSelect) {
      resetSelect = resetSelect === undefined ? true : resetSelect;
      this.loadingCount++;
      this.$http
        .get('/labels')
        .then((res) => {
          this.labels = res.body.map((label) => ({
            text: label.label_name.length === 0 ? 'New' : label.label_name,
            value: label.id,
          }));
          if (resetSelect) {
            this.selectFirstLabel();
          }
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch labels: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    onDuplicate(label) {
      const duplicate = JSON.parse(JSON.stringify(label));
      duplicate.id = uuidv4();
      duplicate.label_name = `${duplicate.label_name} - Copy`;
      duplicate.fields.forEach((field) => {
        field.id = uuidv4();
        if (!['static', 'date', 'time'].includes(field.text_type)) {
          field.text_content = '';
        }
        if (field.text_type === 'date') {
          field.text_content = '01.01.0000';
        }
        if (field.text_type === 'time') {
          field.text_content = '00:00';
        }
      });
      this.loadingCount++;
      this.$http
        .post(`/labels/${duplicate.id}`)
        .send({ label: duplicate })
        .then(() => {
          this.label = duplicate.id;
          this.fetchLabels(false);
        })
        .catch((err) => {
          alert(`Failed to duplicate label: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    onUpdated() {
      this.fetchLabels(false);
    },
    onDeleted(deletedId) {
      this.labels = this.labels.filter((label) => label.value !== deletedId);
      this.selectFirstLabel();
    },
  },
  created() {
    this.fetchLabels();
  },
};
</script>

<style lang="scss" scoped>
</style>
